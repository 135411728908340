import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

const transformTextLink = ({
	link = { link: "", type: "", target: "_self" },
	text = "",
	showInPaid = false,
}) => {
	return {
		text,
		cta: {
			link: {
				link: link.link,
				target: link.target || "_self",
				type: link.type || "internal",
			},
			event: "link_click",
		},
		showInPaid: showInPaid,
	};
};

const transformTextLinkFooter = ({
	link = { link: "", type: "", target: "_self" },
	text = "",
}) => {
	return {
		text,
		cta: {
			link: {
				link: link.link,
				target: link.target || "_self",
				type: link.type || "internal",
			},
			event: BUTTON_INTERACTION.LINK_CLICK,
		},
	};
};

const transformLinkWithIconNew = ({
	link = { link: "", type: "external", target: "_blank" },
	icon = { imageUrl: "", altTag: "" },
}) => ({
	icon: { imageUrl: icon.image, altTag: icon.altTag },
	cta: {
		event: "social_link_click",
		link,
	},
});

const ColumnsTransform = (props) => {
	if (props?.component === "universityAddress") {
		return {
			component: props?.component,
			text: richTextResolver(props?.text),
		};
	} else if (props?.component === "textLinkWR") {
		return props;
	}
	const { title, links } = props;
	return {
		title,
		links: links.map(transformTextLink),
	};
};

export default (footerInput) => {
	let footer = {};
	if (footerInput) {
		const {
			logo = {},
			column1Items = [],
			column2Items = [],
			column3Items = [],
			column4Items = [],
			column5Items = [],
			socialLinks = [],
			legalLinks = [],
			phoneNumber,
			callUsModalDetails,
			email,
			additionalLinks = [],
			footerLogoImage,
			pageSection,
			isPaidLander,
			isLogoCenteredInMobile,
		} = footerInput;

		footer = {
			logo,
			column1Items: column1Items?.map(ColumnsTransform),
			column2Items: column2Items?.map(ColumnsTransform),
			column3Items: column3Items?.map(ColumnsTransform),
			column4Items: column4Items?.map(ColumnsTransform),
			column5Items: column5Items?.map(ColumnsTransform),
			socialLinks: socialLinks?.map(transformLinkWithIconNew),
			legalLinks: legalLinks?.map(transformTextLinkFooter),
			phoneNumber,
			callUsModalDetails: callUsModalDetails?.[0] || {},
			email,
			isPaidLander,
			footerLogoImage,
			additionalLinks: additionalLinks,
			isB2Crebrand: true,
			pageSection,
			isLogoCenteredInMobile,
		};
	}

	return footer;
};
