import { getCloudinaryUrl } from "@website-builder/utilities/utils/CloudinaryUrlGenerator.js";

const IMAGE_WIDTH_BREAKPOINTS = [
	"322px",
	"540px",
	"768px",
	"992px",
	"1120px",
	"1200px",
	"1320px",
	"1440px",
];
const IMAGE_DPR_BREAKPOINTS = ["1.0", "2.0", "3.0"];

export const getPictureSrcSet = (imagePath, imageOptions, sizes) => {
	let srcSets = [];
	const breakPoints = sizes
		? Object.keys(sizes).filter(
				(rw) => IMAGE_WIDTH_BREAKPOINTS.indexOf(rw) !== -1,
			)
		: [];

	if (breakPoints.length) {
		breakPoints.forEach((bp) => {
			let srcSet = "";

			IMAGE_DPR_BREAKPOINTS.forEach((dpr, index) => {
				const width = sizes[bp].width;
				const height = sizes[bp].height;
				const imageUrl = getCloudinaryUrl(imagePath, {
					...imageOptions,
					width,
					dpr,
					height,
					crop: width && height ? "crop" : "limit",
				});

				srcSet += `${imageUrl} ${index + 1}x, `;
			});
			srcSets.push({
				media: `(min-width: ${bp})`,
				srcSet,
			});
		});
	}
	return srcSets.reverse();
};
