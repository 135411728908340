const Vero = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	window._veroq = window._veroq || [];
	(function () {
		const ve = document.createElement("script");
		ve.type = "text/javascript";
		ve.async = true;
		ve.src = `/${assetPrefixPath}/scripts/Vero.js`;
		const s = document.getElementsByTagName("script")[0];
		s.parentNode.insertBefore(ve, s);
	})();
};

export const InitialiseVero = (data) => {
	let vero_id = data.vero_id;
	if (!window._veroq) return;
	window._veroq.push(["init", { api_key: vero_id }]);
	if (data.user.pk) {
		window._veroq.push([
			"user",
			{
				id: `${data.user.pk}`,
				email: `${data.user.email}`,
				first_name: `${data.user.first_name}`,
				last_name: `${data.user.last_name}`,
				user_uuid: `${data.user.user_uuid}`,
			},
		]);
	}
};

export default Vero;
