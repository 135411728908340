const PushCrew = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	(function (p, u, s, h) {
		p._pcq = p._pcq || [];
		p._pcq.push(["_currentTime", Date.now()]);
		s = u.createElement("script");
		s.type = "text/javascript";
		s.async = true;
		s.src = `/${scriptPrefixPath}/PushCrew.js`;
		h = u.getElementsByTagName("script")[0];
		h.parentNode.insertBefore(s, h);
	})(window, document);
};

export default PushCrew;
