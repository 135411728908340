import { getTimeStamp } from "./helpers";
import { GetReferrer } from "./GetReferrer";
import { GetCookie } from "./GetCookie";

export const segmentTrackLead = (
	event_type,
	lead_type,
	course_slug,
	additionalInfo,
) => {
	const referrer = GetReferrer();
	if (window.analytics && window.analytics.track instanceof Function) {
		const eventData = {
			course_slug: course_slug,
			referral: referrer[0],
			referral2: referrer[1],
			lead_type: lead_type,
		};
		if (additionalInfo) {
			if (additionalInfo.workshop_id) {
				eventData.products = [{ product_id: additionalInfo.workshop_id }];
			}
			if (additionalInfo.phoneNumber) {
				eventData.phone_no = additionalInfo.phoneNumber;
			}
		}
		window.analytics.track(event_type, eventData);
	}
};

export const segmentTrackProfileCreated = (event) => {
	if ((window || {}).analytics) {
		window.analytics.track(event);
	}
};

export const segmentTrackEvent = (event, properties) => {
	if ((window || {}).analytics) {
		window.analytics.track(event, { ...properties });
	}
};

export const getSegmentAnonymousId = () => {
	let segmentAnonymousId = decodeURIComponent(GetCookie("ajs_anonymous_id"));
	// Remove " from start and beginning
	segmentAnonymousId = segmentAnonymousId.replace(/^"(.*)"$/g, "$1");
	// encoding the url so that these values don't get chopped off
	return encodeURIComponent(segmentAnonymousId);
};

export const segmentTrackVideoPlayback = (eventName, videoData) => {
	console.log("Segment Event", eventName, videoData);
	if ((window || {}).analytics) {
		window.analytics.track(eventName, videoData);
	}
};

export const segmentIdentifyEvent = (...properties) => {
	console.log("IDENTIFY EVENT", ...properties);
	if ((window || {}).analytics) {
		window.analytics.identify(...properties);
	}
};

export const triggerVWOSegmentEvents = () => {
	const vwoData = window?._vwo_campaignData || {};
	const campaigns = Object.keys(vwoData);
	campaigns.forEach((experiment) => {
		const variationId = vwoData[experiment]["n"];
		const eventData = {
			experiment_ID: experiment,
			variation_ID: variationId,
		};
		segmentTrackEvent("Experiment Viewed", eventData);
	});
};

export const triggerAppFinishedSegmentEvents = ({
	typeFormLink = "",
	courseSlug = "",
}) => {
	if (typeFormLink.startsWith("https://" || "http://")) {
		typeFormLink = typeFormLink.replace("http://", "");
		typeFormLink = typeFormLink.replace("https://", "");
	}
	if (
		window.location.pathname.search(/thank-you/i) !== -1 &&
		typeFormLink &&
		document.referrer.includes(typeFormLink)
	) {
		const appFinishedEventData = {
			course_slug: courseSlug,
			referral: decodeURI(window.location.href),
			referral2: document.referrer || "",
			category: "Typeform",
			lead_type: "AF",
			hit_timestamp: getTimeStamp(),
			container_ID: process.env.GATSBY_GTM_CONTAINER_ID,
			label: decodeURIComponent(window.location.pathname),
		};
		segmentTrackEvent("Application Finished", appFinishedEventData);
	}
};
