const Hubspot = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	const ve = document.createElement("script");
	ve.type = "text/javascript";
	ve.async = true;
	ve.id = "hs-script-loader";
	ve.src = `/${scriptPrefixPath}/Hubspot.js`;
	const s = document.getElementsByTagName("head")[0];
	s.parentNode.insertBefore(ve, s);
};

export default Hubspot;
