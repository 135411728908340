import { useScript } from "@website-builder/utilities-index";

const pixelId = "986855681343301";

const FacebookPixels = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	const body = `(function(f, b, e, v, n, t, s) {
        if(f.fbq) return;
        n= f.fbq= function(){
          n.callMethod?
            n.callMethod.apply(n,arguments) : n.queue.push(arguments);
        };
        if(!f._fbq) f._fbq= n;
        n.push= n;
        n.loaded= !0;
        n.version= '2.0';
        n.queue= [];
        t= b.createElement(e);
        t.async= !0;
        t.src= v;
        s= b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
      })(window, document,'script',
        '/${scriptPrefixPath}/FacebookPixel.js');

        this.fbq = fbq;
        this.fbq('dataProcessingOptions', ['LDU'], 0, 0);
        this.fbq('init', ${pixelId});
        this.fbq('set','agent','tmgoogletagmanager', '986855681343301');
        this.fbq('track', "PageView");`;

	useScript("", true, body, "head");
};

const sendFBPixelEvent = (
	eventName,
	customData,
	single = true,
	custom = false,
) => {
	let trackType = "track";
	trackType = trackType + (single ? "Single" : "");
	trackType = trackType + (custom ? "Custom" : "");

	const args = [trackType, pixelId, eventName, customData];
	if (!single) {
		delete args[1];
	}

	window.fbq(trackType, pixelId, eventName, customData);
};

export default FacebookPixels;
