import GoogleAnalytics from "./b2c/GoogleAnalytics";
import FacebookPixels from "./b2c/FacebookPixels";
import AdWords from "./b2c/AdWords";
import Bing from "./b2c/Bing";
import Hubspot from "./b2c/Hubspot";
import Marketo from "./b2c/Marketo";
import PushCrew from "./b2c/PushCrew";
import QuoraPixels from "./b2c/QuoraPixels";
import Rakuten from "./b2c/Rakuten";
import Segment from "./shared/Segment";
import Twitter from "./b2c/Twitter";
import Vero from "./b2c/Vero";
import NewRelic from "./shared/newrelic";
import GoogleTagManager from "./shared/GoogleTagManager";
import LinkedinInsight from "./b2c/LinkedinInsight";
import Reddit from "./b2c/Reddit";
import Tiktok from "./b2c/Tiktok";
import ExperianUniversalPixel from "./b2c/ExperianUniversalPixel";
export const B2CTrackingScripts = {
	GoogleAnalytics,
	FacebookPixels,
	AdWords,
	Bing,
	Hubspot,
	Marketo,
	PushCrew,
	QuoraPixels,
	Rakuten,
	Segment,
	Twitter,
	Vero,
	NewRelic,
	GoogleTagManager,
	LinkedinInsight,
	Reddit,
	Tiktok,
	ExperianUniversalPixel,
};

export const B2UTrackingScripts = {
	GoogleTagManager,
	Segment,
	NewRelic,
};
