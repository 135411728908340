import { useScript } from "@website-builder/utilities-index";
const Twitter = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	const body = `!function(e,t,n,s,u,a){e.twq||(s=
    e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='/${scriptPrefixPath}/Twitter.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
// Insert Twitter Pixel ID and Standard Event data below
twq('init','numxl');
twq('track','PageView');`;

	useScript("", true, body, "head");
};

export default Twitter;
