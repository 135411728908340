export const BUTTON_INTERACTION = {
	NONE: "none",
	APPLY_BUTTON_CLICK: "apply_button_click",
	APPLICATION_START: "application_start",
	APPLICATION_START_WAIT_LIST: "application_start_WL",
	SYLLABUS_DOWNLOAD: "syllabus_download",
	QUIZ_START: "quiz_start",
	CTA_CLICK: "cta_click",
	COURSE_CTA_CLICK: "course_cta_click",
	LINK_CLICK: "link_click",
	MODAL_FORM_BUTTON_CLICK: "hubspot_form_modal_click",
};

export const FAILED_EVENTS = {
	application_start: "application_start_fail",
	syllabus_download: "syllabus_download_fail",
	quiz_start: "quiz_start_fail",
};

export const CTA_TYPES = {
	hash: "hash",
	typeform: "typeform",
	external: "external",
	internal: "internal",
	syllabus: "syllabus",
};

export const GTM_EVENT_TIMEOUT = 6000;
export const WAIT_TIME_FOR_ANALYTICS = 800;

export const API_EVENTS = [
	BUTTON_INTERACTION.APPLICATION_START,
	BUTTON_INTERACTION.APPLICATION_START_WAIT_LIST,
	BUTTON_INTERACTION.SYLLABUS_DOWNLOAD,
	BUTTON_INTERACTION.QUIZ_START,
];

export const BUTTON_CLICK_EVENTS = {
	APPLY_BUTTON_CLICK: "apply_button_click",
};
