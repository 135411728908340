const getRobotsMetaTagConent = ({ disableIndex, disableFollow }) => {
	const robotsMetaContents = [];
	if (disableFollow) {
		robotsMetaContents.push("nofollow");
	}

	if (disableIndex) {
		robotsMetaContents.push("noindex");
	}

	return robotsMetaContents.join(",");
};

const getOGMetaTags = ({
	ogImage = "",
	ogTitle = "",
	description = "",
	ogUrl = "",
	ogDescription = "",
	twitterTitle = "",
	twitterDescription = "",
	twitterCard = "",
	twitterImage = "",
	fbAppId = "",
	ogWidth = "",
	ogHeight = "",
	googleSiteVerification = "",
}) => {
	const meta = [];

	if (description) {
		meta.push({
			name: "description",
			content: description,
		});
	}

	if (ogTitle) {
		meta.push({
			property: "og:title",
			content: ogTitle,
		});
	}

	if (ogUrl) {
		meta.push({
			property: "og:url",
			content: ogUrl,
		});
	}

	if (ogDescription) {
		meta.push({
			property: "og:description",
			content: ogDescription,
		});
	}

	if (ogImage) {
		meta.push({
			property: "og:image",
			content: ogImage,
		});
	}
	if (twitterTitle) {
		meta.push({
			name: "twitter:title",
			content: twitterTitle,
		});
	}
	if (twitterDescription) {
		meta.push({
			name: "twitter:description",
			content: twitterDescription,
		});
	}
	if (twitterCard) {
		meta.push({
			name: "twitter:card",
			content: twitterCard,
		});
	}
	if (twitterImage) {
		meta.push({
			name: "twitter:image",
			content: twitterImage,
		});
	}
	if (fbAppId) {
		meta.push({
			name: "fb:app_id",
			content: fbAppId,
		});
	}

	if (ogWidth) {
		meta.push({
			property: "og:width",
			content: ogWidth,
		});
	}

	if (ogHeight) {
		meta.push({
			property: "og:height",
			content: ogHeight,
		});
	}

	if (googleSiteVerification) {
		meta.push({
			name: "google-site-verification",
			content: googleSiteVerification,
		});
	}
	meta.push({
		name: "twitter:card",
		content: "summary",
	});

	return meta;
};

export default (content) => {
	//Growth Infra :: Temporary Change | To be updated after release
	let schema;
	if (
		process.env.GATSBY_BUSINESS_VERTICAL === "b2c" ||
		process.env.GATSBY_BUSINESS_VERTICAL === "rb-b2c"
	) {
		if (content.schema && content.schema.length) {
			schema = content.schema[0];
		}
	} else if (process.env.GATSBY_BUSINESS_VERTICAL === "b2u") {
		const { SEO = [] } = content;
		[schema] = SEO;
	}
	if (!schema) return {};

	// Transform Schema and Create Helmet object for the Page
	const {
		title = "",
		ogUrl = "",
		ogImage = "",
		ogTitle = "",
		fbAppId = "",
		ogHeight = "",
		ogWidth = "",
		description = "",
		ogDescription = "",
		twitterTitle = "",
		twitterDescription = "",
		twitterCard = "",
		twitterImage = "",
		jsonSchemas = [],
		canonicalUrl = "",
		disableIndex = false,
		disableFollow = false,
		language = "en",
		googleSiteVerification = "",
	} = schema;

	let helmet = {
		title,
		htmlAttributes: {
			lang: language,
		},
		link: [],
		meta: [],
		script: [],
	};

	if (canonicalUrl) {
		helmet.link.push({
			rel: "canonical",
			href: canonicalUrl,
		});
	}

	if (disableIndex || disableFollow) {
		helmet.meta.push({
			name: "robots",
			content: getRobotsMetaTagConent({ disableIndex, disableFollow }),
		});
	}

	// Add Meta tags
	const meta = getOGMetaTags({
		ogDescription,
		ogImage,
		ogTitle,
		ogUrl,
		description,
		ogHeight,
		ogWidth,
		fbAppId,
		twitterTitle,
		twitterDescription,
		twitterCard,
		twitterImage,
		googleSiteVerification,
	});

	helmet.meta.splice(helmet.meta.length, 0, ...meta);

	//JSON-LD Schemas
	jsonSchemas.forEach(({ schema }) =>
		helmet.script.push({
			type: "application/ld+json",
			children: schema,
		}),
	);

	return helmet;
};
