export const GetReferrerUtmParams = () => {
	const utmParams = new URLSearchParams(window.location.search.substring(1));

	let parsedUtmParams = {};

	utmParams.forEach((value, key) => {
		if (key.toLowerCase().startsWith("utm")) {
			parsedUtmParams[key] = value;
		}
	});
	return parsedUtmParams;
};
