const Rakuten = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	(function (url) {
		let DataLayer = window.DataLayer;
		//     /*Tracking Bootstrap
		//     Set Up DataLayer objects/properties here*/
		if (!window.DataLayer) {
			DataLayer = {};
		}
		if (!DataLayer.events) {
			DataLayer.events = {};
		}
		DataLayer.events.SiteSection = "1";
		const ct = document.createElement("script");
		ct.type = "text/javascript";
		ct.async = true;
		ct.src = url;
		// const loc = document.getElementsByTagName('script')[0];
		const loc = document.getElementsByTagName("title")[0];
		loc.parentNode.insertBefore(ct, loc);
		// loc.parentNode.appendChild(ct);
	})(document.location.protocol + `/${scriptPrefixPath}/Rakuten.js`);
};

export default Rakuten;
