import React, { Component } from "react";

import { B2CTrackingScripts, B2UTrackingScripts } from "./services";
import { useScript } from "@website-builder/utilities/utils/useScript.js";
import { InitialiseHeap } from "./services/b2c/Heap";
import { InitialiseVero } from "./services/b2c/Vero";
import { InitialiseGTM } from "./services/shared/GoogleTagManager";
import { AddReferrer } from "@website-builder/utilities/utils/AddReferrer.js";
import { getCookie } from "@website-builder/utilities/utils/utils.js";
import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader";

global.loadSbAnalytics = true;
const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;

class Analytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			load: false,
		};
		this.renderIntercom =
			this.props?.pageAnalytics?.["Intercom"] ||
			this.props?.trackingScripts?.["Intercom"] ||
			false;
	}

	async componentDidMount() {
		// If analytics are already loaded - Trigger Page Events only
		if (!global.loadSbAnalytics) {
			this.pageEvents();
			return;
		}

		// Pick Page Analytics Configuration if exist otherwise pick Layout Configuration
		let scriptsConfiguration =
			this.props?.pageAnalytics || this.props?.trackingScripts;
		console.info("Selected scriptsConfiguration ==> ", scriptsConfiguration);

		/**
		 * Add referrer to cookie for b2c if exists
		 * Set TrackingScripts based on BUSINESS_VERTICAL
		 */
		let TrackingScripts;
		AddReferrer();
		if (BUSINESS_VERTICAL === "b2c" || BUSINESS_VERTICAL === "rb-b2c") {
			TrackingScripts = B2CTrackingScripts;
		} else if (BUSINESS_VERTICAL === "b2u") {
			TrackingScripts = B2UTrackingScripts;
		}

		// Inserts all the analytics scripts
		Object.keys(scriptsConfiguration).forEach((key) => {
			if (scriptsConfiguration[key] && TrackingScripts[key]) {
				TrackingScripts[key](
					this.props.serviceConfigs,
					this.props.context?.assetPrefixPath,
					this.props.context?.scriptPrefixPath,
					this.props.context?.isRebrandPage,
				);
			}
		});

		// Inserts all the other third party script from the CMS
		if (this.props.otherScripts && this.props.otherScripts.length) {
			this.props.otherScripts.forEach(
				({ src, async, body, script_location }) => {
					useScript(src, async, body, script_location);
				},
			);
		}

		/**
		 * Fetch serviceConfigs for B2C and then Initialize Scripts
		 * Initialize GTM for B2U
		 */
		let serviceConfigs;
		if (BUSINESS_VERTICAL == "b2c" || BUSINESS_VERTICAL == "rb-b2c") {
			try {
				const baseUrl =
					this.props.baseUrl ||
					`${window.location.protocol}//${window.location.host}`;

				await fetch(`${baseUrl}/curriculum/api/service-config/`, {
					credentials: "include",
				})
					.then((res) => res.json())
					.then((res) => (serviceConfigs = res));

				// Initialise analytics services
				this.setState({ data: serviceConfigs });
				if (scriptsConfiguration) {
					if (
						scriptsConfiguration["Heap"] &&
						TrackingScripts["Heap"] &&
						serviceConfigs
					) {
						InitialiseHeap(serviceConfigs);
					}
					if (
						scriptsConfiguration["Vero"] &&
						TrackingScripts["Vero"] &&
						serviceConfigs
					) {
						InitialiseVero(serviceConfigs);
					}
					if (
						scriptsConfiguration["GoogleTagManager"] &&
						TrackingScripts["GoogleTagManager"] &&
						serviceConfigs
					) {
						console.log("LOADING GOOGLE_TAG_MANAGER ... !");
						InitialiseGTM(
							null,
							true,
							serviceConfigs,
							this.props?.context?.scriptPrefixPath,
						);
					}
				}
			} catch (err) {
				console.error("ERROR IN FETCHING /service-config", { err });
			}
		} else if (BUSINESS_VERTICAL == "b2u") {
			if (
				scriptsConfiguration["GoogleTagManager"] &&
				TrackingScripts["GoogleTagManager"]
			) {
				let workshopId = this.props.workshopId;
				console.log("workshopId", workshopId);
				InitialiseGTM(workshopId, false);
			}
		}

		// Trigger page events
		this.pageEvents();
		global.loadSbAnalytics = false;
	}

	pageEvents = () => {
		const gtmInternalUser = getCookie("gtm_internal_user");
		if (gtmInternalUser !== "internal" && BUSINESS_VERTICAL !== "b2u") {
			// Segment Analytics Tracking
			if (window.analytics) {
				window.analytics.page();
			}

			// vero tracking
			if (window._veroq) {
				window._veroq.push(["trackPageview"]);
			}

			// Hubspot page tracking
			if (BUSINESS_VERTICAL == "b2c" || BUSINESS_VERTICAL == "rb-b2c") {
				const _hsq = (window._hsq = window._hsq || []);
				_hsq.push(["setPath", window.location.pathname]);
				_hsq.push(["trackPageView"]);
			}

			// Event for GTM indicating JS navigation
			window.dataLayer = window.dataLayer || [];
		}
	};

	render() {
		return this.renderIntercom ? (
			<div className="intercom-facade">
				<LiveChatLoaderProvider
					providerKey="in1abue5"
					provider="intercom"
					idlePeriod={0}
				>
					<Intercom />
				</LiveChatLoaderProvider>
			</div>
		) : null;
	}
}

export default Analytics;
export { default as analyticsTransform } from "./transform";
