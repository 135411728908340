import { getCookie } from "@website-builder/utilities/utils/utils.js";

const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;
const segmentCookieName = "__dev_es"; // es -> enableSegement
const enableSegment = process.env.GATSBY_ENVIRONMENT === "production";

const Segment = (x, y, z, isRebrandPage = false) => {
	const isForceEnabled = Boolean(getCookie(segmentCookieName));

	const initialize = function () {
		var i = "analytics",
			analytics = (window[i] = window[i] || []);
		if (!analytics.initialize) {
			if (analytics.invoked) {
				window.console &&
					console.error &&
					console.error("Segment snippet included twice.");
			} else {
				analytics.invoked = !0;
				analytics.methods = [
					"trackSubmit",
					"trackClick",
					"trackLink",
					"trackForm",
					"pageview",
					"identify",
					"reset",
					"group",
					"track",
					"ready",
					"alias",
					"debug",
					"page",
					"screen",
					"once",
					"off",
					"on",
					"addSourceMiddleware",
					"addIntegrationMiddleware",
					"setAnonymousId",
					"addDestinationMiddleware",
					"register",
				];
				analytics.factory = function (e) {
					return function () {
						if (window[i].initialized)
							return window[i][e].apply(window[i], arguments);
						var n = Array.prototype.slice.call(arguments);
						if (
							["track", "screen", "alias", "group", "page", "identify"].indexOf(
								e,
							) > -1
						) {
							var c = document.querySelector("link[rel='canonical']");
							n.push({
								__t: "bpc",
								c: (c && c.getAttribute("href")) || void 0,
								p: location.pathname,
								u: location.href,
								s: location.search,
								t: document.title,
								r: document.referrer,
							});
						}
						n.unshift(e);
						analytics.push(n);
						return analytics;
					};
				};
				for (var n = 0; n < analytics.methods.length; n++) {
					var key = analytics.methods[n];
					analytics[key] = analytics.factory(key);
				}
				analytics.load = function (key, n) {
					let urlPrefix = "";
					if (
						process.env.GATSBY_BUSINESS_VERTICAL === "b2c" ||
						process.env.GATSBY_BUSINESS_VERTICAL === "rb-b2c"
					) {
						urlPrefix = "https://analytics-proxy.springboard.com/segment_cdn/";
					} else {
						urlPrefix = "https://cdn.segment.com/analytics.js/v1/";
					}
					let segmentUrl = urlPrefix + key + "/analytics.min.js";
					var t = document.createElement("script");
					t.type = "text/javascript";
					t.async = !0;
					t.setAttribute("data-global-segment-analytics-key", i);
					t.src = segmentUrl;
					t.onload = function () {
						console.log("LOADED SEGMENT SCRIPT!");
						if (BUSINESS_VERTICAL === "b2u") {
							const isRebrandFlow = getCookie("_vis_opt_exp_112_combi") === "2";
							console.log(
								"Triggering segment page event for",
								window?.location?.href,
							);
							window.analytics.page({
								is_rebrand_flow: isRebrandFlow,
								is_rebrand_page: isRebrandPage,
							});
						}
					};
					t.onerror = function () {
						console.error("COULD NOT LOAD SEGMENT SCRIPT!");
					};
					var r = document.getElementsByTagName("script")[0];
					r.parentNode.insertBefore(t, r);
					analytics._loadOptions = n;
				};
				analytics._writeKey = process.env.GATSBY_SEGMENT_KEY;
				analytics.SNIPPET_VERSION = "5.2.0";
				analytics.load(process.env.GATSBY_SEGMENT_KEY);
				analytics.page();
			}
		}
	};

	if (enableSegment || isForceEnabled) {
		initialize();
	} else {
		const url = "https://springboard-edu.atlassian.net/wiki/x/1ACas";
		console.warn(`Segment has been disabled in namespace deployment. ${url}`);
	}
};

export default Segment;
