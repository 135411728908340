import { useScript } from "@website-builder/utilities-index";

const GOOGLE_ANALYTICS_ID = process.env.GATSBY_GOOGLE_ANALYTICS_ID; //using GA ID from env files

const GoogleAnalytics = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	const body = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','/${assetPrefixPath}/scripts/GoogleAnalytics.js','ga');

ga('create', ${GOOGLE_ANALYTICS_ID}, 'auto');`;

	useScript("", true, body, "head");
};
export default GoogleAnalytics;
