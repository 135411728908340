const QuoraPixel = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	(function (q, e, v, n, t, s) {
		if (q.qp) {
			return;
		}
		n = q.qp = function () {
			n.qp ? n.qp.apply(n, arguments) : n.queue.push(arguments);
		};
		n.queue = [];
		t = document.createElement(e);
		t.async = !0;
		t.src = v;
		s = document.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, "script", `/${scriptPrefixPath}/QuoraPixel.js`);
	window.qp("init", "a9c2294b874b431eb833598e0e39cb8f");
	window.qp("track", "ViewContent");
};

export default QuoraPixel;
