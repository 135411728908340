import { useScript, useNoScript } from "@website-builder/utilities-index";
const Reddit = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	let body = `var now=Date.now();var i=new Image();i.src="https://alb.reddit.com/snoo.gif?q=CAAHAAABAAoACQAAAC81Yx1qAA==&s=jrPoRxtqo2Ws9CrVYkzFCbdZQ34YR8Kp1zI61tnpiis=&ts="+now;`;

	useScript("", true, body, "head");

	body = `<img height="1" width="1" style="display:none"
  src="https://alb.reddit.com/snoo.gif?q=CAAHAAABAAoACQAAAC81Yx1qAA==&s=jrPoRxtqo2Ws9CrVYkzFCbdZQ34YR8Kp1zI61tnpiis="/>`;

	useNoScript(body, "head");
};

export default Reddit;
