// Colors
export const COLOR_TEXT_PRIMARY_B2C = "#48485E";
export const COLOR_TEXT_PRIMARY_B2U = "#333333";
export const COLOR_LINK_HOVER = "#34F8AB";
export const COLOR_INPUT_LIGHT = "#787E8C";
export const COLOR_TEXT_HIGHLIGHT = "#00c09d";
export const COLOR_RB_PURPLE_300 = "#5e0bd1";
export const COLOR_RB_PURPLE_200 = "#944ff2";
export const COLOR_RB_PURPLE_100 = "#d8bdff";
export const COLOR_RB_PURPLE_50 = "#f6f0ff";
export const COLOR_RB_NIGHTSHADE_800 = "#05082b";
export const COLOR_HIGHLIGHT_DARK = "#019076";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
export const COLOR_BLURPLE = "#4b95eb";
export const COLOR_RED = "#ff0000";
export const COLOR_BOX_SHADOW = "rgba(0, 0, 0, 0.1)";
export const COLOR_ERROR_RED = "#dd0d17";
export const COLOR_BORDER_GREY = "#c5c5c5";
export const COLOR_LIGHT_GREY = "#efeff6";
export const COLOR_HYPERLINK = "#4f8ab0;";
export const COLOR_HYPERLINK_HOVER = "#2d87b0";
export const COLOR_DARK_BLUE = "#072b77";
export const COLOR_TRANSLUCENT_WHITE = "rgba(255, 255, 255, 0.8)";
export const COLOR_FACEBOOK_BLUE = "#3c5897";
export const COLOR_LINKEDIN_BLUE = "#4778b6";
export const COLOR_GOOGLE_ORANGE = "#b23225";
export const COLOR_FLAT_RED = "#ef2964";
export const COLOR_HOVER_LINK_LIGHT_BLUE = "#2d87b1";
export const COLOR_SPRINGBOARD_GRADIENT =
	"linear-gradient(to left,#36b791,#d0e02c)";
export const COLOR_NOBEL_GREY = "#9B9B9B";
export const COLOR_BORDER_L_GREY = "#D8D8D8";
export const COLOR_LIGHT_GREEN = "#F6FDFB";
export const COLOR_BACKGROUND_GREEN = "#f0f8f7";
export const COLOR_DARK_RED = "#A94442";
export const COLOR_BORDER_INPUT = "#F0F0F0";
export const COLOR_GREEN_BUTTON = "#00AB8C";
export const COLOR_DARK_GREEN_BUTTON = "#2CA893";
export const COLOR_DARK_LINK = "#337AB7";
export const COLOR_LIGHT_LINK = "#185080";
export const COLOR_LEAF_GREEN = "#75B740";
export const COLOR_LINK_GREEN = "#337AB7";
export const COLOR_LINK_HOVER_BLUE = "#185080";
export const COLOR_BUTTON_HOVER = "#A2181D";
export const COLOR_BUTTON_BORDER_GREY = "#F0F0F0";
export const COLOR_INPUT_SHADOW = "#75B740";
export const COLOR_DARK_GREY = "#a4a4a4";
export const COLOR_GREY_BORDER = "#979797";
export const COLOR_BORDER_LIGHT_GREY = "#E5E5E5";
export const COLOR_HIGHLIGHT_GREY = "#F6F6F6";
export const COLOR_LIGHT_GREEN_BACKGROUND = "#AADFD6";
export const COLOR_LIGHT_GREY_CYAN = "#F0F8F7";
export const COLOR_PANTONE_GREY = "#C5C5CF";
export const COLOR_GREEN = "#008000";
export const COLOR_NAVIGATION_GREY = "#F4F2F2";
export const COLOR_SELECTED_LINK = "#CCDCD3";
export const COLOR_GREY = "#A7A7A7";
export const COLOR_NAV_HOVER = "#AFC7B9";
export const COLOR_SKY_BLUE = "#A9DEFF";
export const COLOR_GREY_RADIANT = "#C5C5CF";
export const COLOR_TEAL = "#d6e8e3";
export const STAR_RATE_TRUE = "#ffc008";
export const STAR_RATE_FALSE = "#faeed2";
export const COLOR_ORANGE = "#FF7155";
export const COLOR_LIGHTEST_GREY = "#f9f9f9";
export const COLOR_LIGHT_YELLOW = "#cfae38";
export const COLOR_BLUE = "#013590";
export const FORD_GRAY = "#979797";
export const PALE_BLACKISH_PURPLE = "#48485E";
export const COLOR_GREY_DARK = "#020202";
export const COLOR_JAVA_LIGHT = "#1ebf9d";

export const TEXT_COLORS = {
	HEADING_DARK: { base: "primary", color: "neutral-nightshade", shade: "800" },
	HEADING_LIGHT: { base: "primary", color: "neutral", shade: "white" },
	TEXT_DARK: { base: "primary", color: "neutral-nightshade", shade: "600" },
	TEXT_LIGHT: { base: "primary", color: "neutral", shade: "white" },
};
