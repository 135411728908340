module.exports = (story, variables = [], isUrl) => {
	try {
		let updatedStoryList =
			typeof story === "string" ? story : JSON.stringify(story);
		variables.forEach(({ key, value, url }) => {
			const replacingValue = isUrl
				? `<a href="${url}" target="_blank" rel="noopener noreferrer">${value}</a>`
				: value;
			updatedStoryList = updatedStoryList
				.split(`\#\{${key}\}`)
				.join(replacingValue);
		});
		const parsedStoryList = isUrl
			? updatedStoryList
			: JSON.parse(updatedStoryList);
		return parsedStoryList;
	} catch (err) {
		!isUrl && console.error(`Unable to replace with global variable`, err);
		return story;
	}
};
