import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import { DEFAULT_LOGO_KEY } from "../constants/config/config";
import modifyContentWithGlobalVariable from "./globalVariable";

export const richTextResolver = (content, variables = null) => {
	let contentInHTML = new RichTextResolver().render(content);
	try {
		contentInHTML = modifyContentWithGlobalVariable(
			contentInHTML,
			variables,
			true,
		);
		return contentInHTML;
	} catch (err) {
		console.log("Error in replacing global variable", err);
		return new RichTextResolver().render(content);
	}
};

export const getHTMLContent = (content = "", target = "_blank") => {
	if (typeof content === "string") {
		return { value: content, isTextContent: true };
	} else {
		const htmlContent = content?.html;
		return {
			value: htmlContent?.replace(/<a href=/gi, `<a target='${target}' href=`),
			isTextContent: false,
		};
	}
};

export const transformLinkWithLabel = (link) => {
	if (
		link.hasOwnProperty("link") &&
		link.link &&
		link.hasOwnProperty("type") &&
		link.type
	) {
		return {
			link: link.link,
			target: link.target || "_self",
			type: link.type,
		};
	} else {
		return null;
	}
};

export const getImageConfig = (image, basicConfig = null) => {
	if (image) {
		return {
			imageUrl: image.image,
			altTag:
				image.altTag ||
				(basicConfig && basicConfig.name ? basicConfig.name : ""),
		};
	} else {
		console.error("Could not get Image config");
	}
};

export const getStoriesByContentType = (stories, contentType) => {
	return stories.filter(({ full_slug, content }) => {
		try {
			if (full_slug.toLowerCase().indexOf("layout") === -1) {
				const { SEO, component } =
					typeof content === "string" ? JSON.parse(content) : content;
				if (component === contentType) {
					if (SEO && SEO.length) {
						const [{ disableIndex = false }] = SEO;
						return !disableIndex;
					}
					return true;
				}
			}
		} catch (err) {
			console.error(`Unable to process data,${full_slug} - ${content}`, err);
		}
		return false;
	});
};

// return pages with disableIndex set to false
export const getIndexablePages = (stories) => {
	return stories.filter((story) => {
		const {
			content: { SEO },
		} = story;
		if (SEO && SEO.length) {
			const [{ disableIndex = false }] = SEO;
			return !disableIndex;
		} else return true;
	});
};

export const filterBySlug = (stories, fullSlug) => {
	return stories.filter(({ full_slug }) => {
		return full_slug.startsWith(fullSlug);
	});
};

export const getLogoOrImageConfig = (config, basicConfig = null) => {
	const {
		type = "primary",
		height = "55",
		width = "350",
		mobileHeight,
		mobileWidth,
		image,
		altTag = basicConfig ? basicConfig.name : "",
		altText = "",
	} = config;
	if (type === "default") {
		return {
			url:
				image === DEFAULT_LOGO_KEY.PRIMARY
					? basicConfig.primaryLogo
					: basicConfig.secondaryLogo,
			width: parseInt(width, 10),
			height: parseInt(height, 10),
			altTag,
		};
	}

	if (type === "custom") {
		return {
			url: image,
			width: parseInt(width, 10),
			height: parseInt(height, 10),
			altTag,
		};
	}

	//for Backward compatibility
	if (
		type === DEFAULT_LOGO_KEY.PRIMARY ||
		type === DEFAULT_LOGO_KEY.SECONDARY
	) {
		return {
			type,
			width,
			height,
			mobileHeight: mobileHeight ? mobileHeight : height,
			mobileWidth: mobileWidth ? mobileWidth : width,
			altText,
		};
	}
};

export const getRemainingDaysAndHours = (endDate, currentTime) => {
	[endDate] = endDate.split(" "); // doing to this for safari error
	endDate = new Date(endDate);
	endDate.setMinutes(0); // setting back 0 as it came from API
	endDate.setHours(0); // setting back 0 as it came from API
	endDate.setTime(endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000); //Convert to GMT
	let endTimeEST = new Date(endDate.getTime() + 420 * 60000); //420 for make it EST = UTC -7:00 hours
	endTimeEST.setDate(endTimeEST.getDate() + 1); // INCLUDE END DAY
	const diffTime = Math.floor(endTimeEST - currentTime);
	const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
	const res = {
		remainingDays: Math.floor(diffHours / 24),
		remainingHours: Math.floor(diffHours % 24) + 1, // TODO: Reconfirm this logic
	};
	return res;
};

export const replaceCharWith = (replaceRegExOrStr, replacewith, str) => {
	return str.replace(replaceRegExOrStr, replacewith);
};
