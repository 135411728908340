import { useScript } from "@website-builder/utilities-index";
import { GetReferrer } from "@website-builder/utilities-index";

const Heap = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	const body = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="/${scriptPrefixPath}/Heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
`;

	useScript("", true, body, "head");
};

export const InitialiseHeap = (data) => {
	if (!window.heap) return;
	window.heap.load(data.heap_id);
	if (data.user.pk) {
		window.heap.identify(`${data.user.pk}`);
		window.heap.addUserProperties({
			email: `${data.user.email}`,
			first_name: `${data.user.first_name}`,
			last_name: `${data.user.last_name}`,
			user_uuid: `${data.user.user_uuid}`,
		});
	}
};

export const heapTrackLead = (event_type, workshop_name, workshop_id) => {
	// Track workshop lead events in heap
	const referrer = GetReferrer();
	const eventName = `${event_type} - ${workshop_name} - Email Submit`;
	window.heap.track(eventName, {
		workshop_id: workshop_id,
		referrer: referrer[0],
		referrer2: referrer[1],
	});
};

export const heapTrackHubLead = (event_type, hub_slug, event_name, hub_id) => {
	// Track hub lead events in heap
	const referrer = GetReferrer();
	const eventName = `${event_type} - ${hub_slug} - ${event_name}`;
	window.heap.track(eventName, {
		hub_id: hub_id,
		referrer: referrer[0],
		referrer2: referrer[1],
	});
};

export default Heap;
