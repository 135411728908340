import React, { useEffect, useState } from "react";

import { Templates } from "@website-builder/ui/rbB2c";
//B2C Imports
//import { Templates as B2C_Layouts } from "@website-builder/ui/b2c";
import { GlobalStyles } from "@website-builder/ui/shared";

//B2U Imports
import { Helmet } from "react-helmet";
//import { Layouts } from "@website-builder/ui/b2u";
//Common Imports
import { seoTransform } from "@website-builder/ui/shared";
import { SEO } from "@website-builder/ui/shared";
import {
	LAYOUT_CONFIG,
	DEFAULTS,
} from "@website-builder/utilities/constants/config/config.js";
import Analytics from "@website-builder/analytics";
import { analyticsTransform } from "@website-builder/analytics";
import { getSegmentURL } from "@website-builder/utilities/utils/utils.js";
import { UniversityTheme } from "@website-builder/utilities/utils/UniversityTheme.js";
import {
	getFaviconInfo,
	getOriginUrl,
} from "@website-builder/utilities/utils/helpers.js";
import { B2U_REBRAND_CONTENT_TYPES } from "@website-builder/b2u/constants";
const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;

const PageTemplate = ({ pageContext }) => {
	const [loadAnalytics, setLoadAnalytics] = useState(false);

	useEffect(() => {
		setLoadAnalytics(true);
	}, []);

	const {
		story,
		basicConfig = {},
		storyList,
		globalVariableWithURL = [],
		formData = [],
	} = pageContext;
	const { customCssCode = null, customJsCode = null } = story.content;
	const {
		primaryColor,
		secondaryColor,
		fontFamily,
		fontUrl,
		transformerParams,
		mentorProfilesData,
	} = basicConfig;

	let preFetchUrlList = [];
	let VerticalTemplate = null;
	let LayoutTemplate = null;
	let helmet = null;
	let analyticsData = null;
	let workshopId = "";

	if (story.content.workshop_id || story.content.workshopId) {
		workshopId = story.content.workshop_id || story.content.workshopId;
	}

	//Transform SEO/Helmet Data from story
	helmet = seoTransform(story.content);
	//Transform Analytics Data from story
	analyticsData = analyticsTransform(story.content);

	//Get favIcon URL from DataSource
	const favIcon = getFaviconInfo(basicConfig["favicon"] || DEFAULTS.FAV_ICON);

	//Get Segment URL to Add Segment Preload Tag
	const segmentUrl = getSegmentURL(BUSINESS_VERTICAL);

	if (BUSINESS_VERTICAL === "b2c") {
		//Set preFetchUrlList for B2C
		const { B2C_PREFETCH_URL_LIST } = require("../constants");
		preFetchUrlList = B2C_PREFETCH_URL_LIST;

		//Set Layout for B2C
		LayoutTemplate = Templates[[LAYOUT_CONFIG["B2C"][story.content.component]]];
		VerticalTemplate = () => {
			return <></>;
		};
	} else if (BUSINESS_VERTICAL === "rb-b2c") {
		//Set preFetchUrlList for rb-b2c
		const { RB_B2C_PREFETCH_URL_LIST } = require("../constants");
		preFetchUrlList = RB_B2C_PREFETCH_URL_LIST;

		//Set Layout for rb-b2c
		LayoutTemplate =
			Templates[[LAYOUT_CONFIG["RB_B2C"][story.content.component]]];
		VerticalTemplate = () => {
			return <></>;
		};
	} else if (BUSINESS_VERTICAL === "b2u") {
		//Set preFetchUrlList for B2U
		const { B2U_PREFETCH_URL_LIST } = require("../constants");
		preFetchUrlList = B2U_PREFETCH_URL_LIST;
		const universityCss =
			story?.content?.layout?.content?.customCssCode?.content;

		//Set Layout for B2U
		LayoutTemplate = Templates[LAYOUT_CONFIG["B2U"][story.content.component]];

		//Add Hubspot Script URL for B2U
		const originUrl = getOriginUrl();
		const hubspotUrl = originUrl
			? `${originUrl}/analytics-cache/hsforms.js`
			: "";

		transformerParams.isRebrandPage = B2U_REBRAND_CONTENT_TYPES.includes(
			story.content.component,
		);

		VerticalTemplate = () => {
			return (
				<>
					<Helmet>
						{hubspotUrl && (
							<script src={hubspotUrl} defer="true" data-local-hsform="true" />
						)}
						{universityCss && (
							<style type="text/css" data-type="universityCss">
								{universityCss}
							</style>
						)}
					</Helmet>
					{BUSINESS_VERTICAL === "b2u" && (
						<UniversityTheme basicConfig={basicConfig} />
					)}
				</>
			);
		};
	} else {
		console.error("BUSINESS_VERTICAL not passed/matched inside template!");
		VerticalTemplate = () => {
			return (
				<>
					<h2>"BUSINESS_VERTICAL not passed/matched!"</h2>
				</>
			);
		};
	}

	return (
		<>
			<GlobalStyles vertical={BUSINESS_VERTICAL} isPreview={false} />
			<Helmet>
				<link rel="icon" type={favIcon.type} href={favIcon.url} />
				{BUSINESS_VERTICAL === "rb-b2c" && [
					<link
						rel="preload"
						as="font"
						type="font/woff2"
						href={`https://www.springboard.com/site-builder-assets/shared/fonts/hafferXH/HafferXH-Regular.woff2`}
						crossOrigin="anonymous"
					/>,
					<link
						rel="preload"
						as="font"
						type="font/woff2"
						href={`https://www.springboard.com/site-builder-assets/shared/fonts/hafferXH/HafferXH-Bold.woff2`}
						crossOrigin="anonymous"
					/>,
					<link
						rel="preload"
						as="font"
						type="font/woff2"
						href={`https://www.springboard.com/site-builder-assets/shared/fonts/hafferXH/HafferXH-SemiBold.woff2`}
						crossOrigin="anonymous"
					/>,
					<link
						rel="preload"
						as="font"
						type="font/woff2"
						href={`https://www.springboard.com/site-builder-assets/shared/fonts/hafferXH/HafferXH-Medium.woff2`}
						crossOrigin="anonymous"
						id="last-font-preload"
					/>,
				]}
			</Helmet>
			<Helmet>
				{preFetchUrlList.map((url, index) => (
					<link key={index} rel="preconnect" href={url} />
				))}
				{preFetchUrlList.map((url, index) => (
					<link key={index} rel="dns-prefetch" href={url} />
				))}
				{fontUrl && (
					<link
						rel="preload"
						as="font"
						type="font/woff2"
						href={fontUrl}
						crossOrigin="anonymous"
					/>
				)}
				{fontUrl && (
					<link
						href={fontUrl}
						rel="stylesheet"
						media="print"
						onLoad="this.media='all'"
					/>
				)}
				{segmentUrl && <link rel="preload" as="script" href={segmentUrl} />}
			</Helmet>
			{(primaryColor || secondaryColor || fontFamily) && (
				<Helmet>
					<style type="text/css" data-type="cssVar">
						{`
								:root{
									${primaryColor ? `--primary: ${primaryColor}` : ""};
									${primaryColor ? `--primaryLight: ${primaryColor}20` : ""};
									${secondaryColor ? `--secondary: ${secondaryColor}` : ""};
								}
								${
									fontFamily &&
									`body{
									font-family: ${fontFamily};
									}`
								}
						`}
					</style>
				</Helmet>
			)}

			<SEO helmet={helmet} />
			<LayoutTemplate
				story={story}
				basicConfig={basicConfig}
				storyList={storyList}
				globalVariableWithURL={globalVariableWithURL}
				mentorProfilesData={mentorProfilesData}
				formData={formData}
			/>
			{loadAnalytics && (
				<Analytics
					trackingScripts={analyticsData?.trackingScripts}
					// todo - pageAnalytics is partially implemented
					// pageAnalytics={analyticsData?.pageAnalytics}
					otherScripts={analyticsData?.otherScripts}
					workshopId={workshopId}
					context={transformerParams}
					baseUrl={process.env.GATSBY_API_BASE_URL}
				/>
			)}
			<VerticalTemplate />
			{customCssCode && customCssCode.content && (
				<Helmet>
					<style type="text/css" data-type="customCssCode">
						{customCssCode.content}
					</style>
				</Helmet>
			)}
			{BUSINESS_VERTICAL !== "b2u" && customJsCode && customJsCode.content && (
				<Helmet>
					<script type="text/javascript" data-type="addCustomJSCode">
						{`
              (() => {
                let d = document;
                let a = d.createElement("style");
                let b = "body {opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}";
                let h = d.getElementsByTagName("head")[0];
                a.setAttribute("id", "custom_js_hide");
                a.setAttribute("type", "text/css");
                if (a.styleSheet) a.styleSheet.cssText = b;
                else a.appendChild(d.createTextNode(b));
                h.appendChild(a);
                console.info("adding custom js code to window object");
                window.__customJsCode = {
                  init: function () {
                    ${customJsCode.content}
                  }
                }
              })()
            `}
					</script>
				</Helmet>
			)}
		</>
	);
};

export default PageTemplate;
