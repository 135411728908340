export const FIRSTNAME_REQUIRED = "First name is required";
export const LASTNAME_REQUIRED = "Last name is required";
export const EMAIL_REQUIRED = "Email is required.";
export const PHONE_NUMBER_REQUIRED = "Phone number is required.";
export const COUNTRY_CODE_REQUIRED = "Country code is required.";
export const CONSENT_REQUIRED = "Please consent to our terms and services.";
export const PASSWORD_REQUIRED = "Password is required (min 8 chars)";

export const EMAIL_INVALID = "Please enter valid email";
export const PHONE_NUMBER_INVALID = "Please enter valid phone number";
export const COUNTRY_CODE_INVALID = "Please enter a valid country code";

export const LOGIN_FORM_ERROR =
	"The e-mail address and/or password you specified are not correct.";
export const SIGNUP_FORM_ERROR = "A user is already registered with this email";
export const INVALID_REQUEST = "Invalid Request";
export const NAME_REQUIRED = "Name is required";
