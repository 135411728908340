import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
	helmet: {
		title,
		htmlAttributes = { lang: "en" },
		link,
		meta = [],
		script = [],
	},
}) => {
	return (
		<Helmet
			title={title}
			htmlAttributes={htmlAttributes}
			link={link}
			meta={meta}
		>
			{script.map(({ type, children = "" }, index) => (
				<script type={type} key={index}>
					{children}
				</script>
			))}
		</Helmet>
	);
};

export default SEO;
