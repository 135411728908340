exports.GET_B2U_STORIES = `
{
  allStoryblokEntry {
    nodes {
      full_slug
      name
      is_startpage
      id
      uuid
      slug
      published_at
      created_at
      first_published_at
      content
      lastModified: published_at(formatString: "YYYY-MM-DD")
    }
  }
}
`;

exports.GET_B2C_STORIES = `
{
  allStoryblokEntry {
    nodes {
      full_slug
      name
      is_startpage
      id
      uuid
      slug
      published_at
      created_at
      first_published_at
      content
      parent {
        id
      }
      lastModified: published_at(formatString: "YYYY-MM-DD")
    }
  }
}
`;
exports.GET_RB_B2C_STORIES = `
{
  allStoryblokEntry {
    nodes {
      full_slug
      name
      is_startpage
      id
      uuid
      slug
      published_at
      created_at
      first_published_at
      content
      parent {
        id
      }
      lastModified: published_at(formatString: "YYYY-MM-DD")
    }
  }
}
`;

exports.GET_DATASOURCE = `
{
  allStoryblokDatasourceEntry {
    nodes {
      id
      name
      value
      data_source
    }
  }
}
`;

exports.GET_MENTORS_DATASOURCE = `
{
  mentorList {
    datasource_entries {
      id
      name
      value
    }
  }
}
`;

exports.GET_GLOBAL_VARIABLES_DATASOURCE = `
{
  globalVariables {
    datasource_entries {
      name
      value
    }
  }
}
`;

exports.B2C_PREFETCH_URL_LIST = [
	"www.googletagmanager.com",
	"bat.bing.com",
	"load.fomo.com",
	"https://dev.visualwebsiteoptimizer.com",
	"https://cdn.heapanalytics.com",
	"d3qxef4rp70elm.cloudfront.net",
	"www.googleadservices.com",
	"js.hs-scripts.com",
	"munchkin.marketo.net",
	"intljs.rmtag.com",
	"https://analytics-proxy.springboard.com/segment_cdn",
	"https://connect.facebook.net",
	"https://s3.amazonaws.com",
	"https://consent.linksynergy.com",
];

exports.RB_B2C_PREFETCH_URL_LIST = [
	"www.googletagmanager.com",
	"analytics-proxy.springboard.com",
	"cdnjs.cloudflare.com",
	"maxcdn.bootstrapcdn.com",
	"res.cloudinary.com",
	"cdnmd.global-cache.online",
	"api.springboard.com",
	"dev.visualwebsiteoptimizer.com",
	"cdn.segment.com",
];

exports.B2U_PREFETCH_URL_LIST = [
	"https://fonts.gstatic.com",
	"https://fonts.googleapis.com",
	"https://www.googletagmanager.com/",
	"https://cdnjs.cloudflare.com",
	"https://js.hsforms.net",
	"https://res.cloudinary.com/",
	"https://dev.visualwebsiteoptimizer.com/",
];

exports.B2U_LAYOUTS = [
	"About.layout",
	"FAQ.layout",
	"ThankYou.layout",
	"Lead.layout",
	"Experience.layout",
	"Program.layout",
	"Home.layout",
	"BlogIndex.layout",
	"Blog.layout",
	"RebrandPage.layout",
	"RebrandProgram.layout",
	"RebrandBlogIndex.layout",
	"RebrandBlog.layout",
	"RebrandBlogIndex.featuredPosts",
	"RebrandBlogIndex.bannerPost",
	"RebrandBlog.featuredPosts",
];
exports.B2U_ADDITIONAL_RESOLVE_RELATIONS = [
	"RebrandBlogIndex.featuredPosts",
	"RebrandBlogIndex.bannerPost",
	"RebrandBlog.featuredPosts",
];

exports.B2C_LAYOUTS = [
	"postapp.layout",
	"course.layout",
	"landing-page.layout",
	"student-success-page.layout",
	"student-success-homepage.layout",
	"student-success-homepage.data",
	"StudentSuccessBanner.featured_alumni",
	"student-success-homepage.video_alumni",
	"student-success-page.filter_tag",
	"eBook-Homepage-Schema.layout",
	"eBook-Homepage-Schema.data",
	"eBook-Page.layout",
	"Resources.layout",
	"Learningpath-Homepage-Schema.layout",
	"Learningpath-Homepage-Schema.data",
	"Learningpath-schema.layout",
	"AlumniStories.stories",
	"AlumniStories.video_stories",
	"ResourcesWithCTA.resources",
	"HeroWithCTA.resources",
];

exports.RB_B2C_LAYOUTS = [
	"postapp.layout",
	"course.layout",
	"landing-page.layout",
	"student-success-page.layout",
	"student-success-homepage.layout",
	"student-success-homepage.data",
	"student-success-homepage.video_alumni",
	"student-success-page.filter_tag",
	"eBook-Homepage-Schema.layout",
	"eBook-Homepage-Schema.data",
	"Resources.layout",
];
/**
 * We get all .layout resolveRelations in the .env file on Build Triggers
 * We are storing the additional resolveRelation values here.
 * This is added to the original resolveRelations values and then used.
 */
exports.B2C_ADDITIONAL_RESOLVE_RELATIONS = [
	"student-success-homepage.data",
	"StudentSuccessBanner.featured_alumni",
	"student-success-homepage.video_alumni",
	"student-success-page.filter_tag",
	"eBook-Homepage-Schema.data",
	"Learningpath-Homepage-Schema.data",
	"AlumniStories.stories",
	"AlumniStories.video_stories",
	"ResourcesWithCTA.resources",
	"HeroWithCTA.resources",
];
