export default (content) => {
	const { layout, analytics = {} } = content;
	let pageAnalytics;
	if (analytics.value) {
		pageAnalytics = analytics.value;
		pageAnalytics = pageAnalytics.reduce(function (map, obj) {
			let isTrueSet =
				obj.value === null || obj.value === "null"
					? obj.originalValue === true || obj.originalValue === "true"
					: obj.value === true || obj.value === "true";
			map[obj.key] = isTrueSet ? true : false;
			return map;
		}, {});
	} else {
		console.log("pageAnalytics data not received");
	}
	if (layout && layout.content) {
		let {
			content: { trackingScripts: scripts, otherScripts = [] },
		} = layout;
		if (otherScripts && otherScripts.length) {
			otherScripts = otherScripts.map(
				({ src = "", body = "", async = true, script_location = "head" }) => ({
					src,
					body,
					async,
					script_location,
				}),
			);
		}
		if (scripts && scripts.length) {
			const [trackingScripts] = scripts;
			delete trackingScripts.component;
			delete trackingScripts._editable;
			delete trackingScripts._uid;
			return {
				trackingScripts,
				pageAnalytics,
				otherScripts,
			};
		}
	}
	return {
		pageAnalytics,
	};
};
