// const GATSBY_GTM_CONTAINER_ID = "GTM-MX6WG5";
const GATSBY_GTM_CONTAINER_ID = process.env.GATSBY_GTM_CONTAINER_ID; //using GTM ID from env files
const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;
const IS_PRODUCTION =
	process.env.GATSBY_ENVIRONMENT === "production" ? true : false;
const IS_LOCAL =
	process.env.GATSBY_ANALYTICS_ENABLED_IN_PREVIEW === "true" ? true : false;
const STAGING_GTM_AUTH = "afPnq1o_cgGv8N2WIeZgXA";
const STAGING_GTM_PREVIEW = "env-209";
const LIVE_GTM_AUTH = "k_lijJ3KCtz31IwQJ-yzPg";
const LIVE_GTM_PREVIEW = "env-1";
let s;
const GoogleTagManager = () => {
	s = document.createElement("iframe");
	if (IS_PRODUCTION) {
		s.src = "//www.googletagmanager.com/ns.html?id=" + GATSBY_GTM_CONTAINER_ID;
	} else if (IS_LOCAL) {
		s.src =
			"https://www.googletagmanager.com/ns.html?id=" + GATSBY_GTM_CONTAINER_ID;
	} else {
		s.src =
			"https://www.googletagmanager.com/ns.html?id=" +
			GATSBY_GTM_CONTAINER_ID +
			"&gtm_auth=" +
			STAGING_GTM_AUTH +
			"&gtm_preview=" +
			STAGING_GTM_PREVIEW;
	}
	s.style.height = "0";
	s.style.width = "0";
	s.style.display = "none";
	s.style.visibility = "hidden";
};

const gtmFunction = (w, d, s, l, i, scriptPrefixPath) => {
	w[l] = w[l] || [];
	w[l].push({
		"gtm.start": new Date().getTime(),
		event: "gtm.js",
	});
	let f = d.getElementsByTagName(s)[0],
		j = d.createElement(s),
		dl = l !== "dataLayer" ? "&l=" + l : "";
	j.async = true;
	if (BUSINESS_VERTICAL === "b2u") {
		j.src = `https://www.googletagmanager.com/gtm.js?id=${GATSBY_GTM_CONTAINER_ID}`;
	} else {
		if (IS_PRODUCTION) {
			j.src = `/${scriptPrefixPath}/GTM.js`;
		} else if (IS_LOCAL) {
			j.src = `https://www.googletagmanager.com/gtm.js?id=${GATSBY_GTM_CONTAINER_ID}`;
		} else {
			j.src =
				`https://www.googletagmanager.com/gtm.js?id=${GATSBY_GTM_CONTAINER_ID}` +
				"&gtm_auth=" +
				STAGING_GTM_AUTH +
				"&gtm_preview=" +
				STAGING_GTM_PREVIEW;
		}
	}
	f.parentNode.insertBefore(j, f);
};

export const InitialiseGTM = (
	workshopId,
	isB2C,
	data,
	scriptPrefixPath = "",
) => {
	window.dataLayer = window.dataLayer || [];
	if (isB2C) {
		if (data?.user?.user_uuid) {
			window.dataLayer.push({
				user_uuid: data?.user?.user_uuid,
				userId: data?.user?.pk,
			});
		}
	} else {
		window.dataLayer.push({
			workshopId,
		});
	}
	gtmFunction(
		window,
		document,
		"script",
		"dataLayer",
		GATSBY_GTM_CONTAINER_ID,
		scriptPrefixPath,
	);

	document.body.insertBefore(s, document.body.firstChild);
};

export default GoogleTagManager;
