import loadable from "@loadable/component";

export const NavigationHeader = loadable(() => import("./NavigationHeader"));
export const NavigationFooter = loadable(() => import("./NavigationFooter"));
export const NavigationHeaderGeneric = loadable(
	() => import("./NavigationHeaderGeneric"),
);

export { default as headerTransformRebrand } from "./headerTransform";
export { default as footerTransformRebrand } from "./NavigationFooter/transform";
