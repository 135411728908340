import { createGlobalStyle, css } from "styled-components";
// Just by importing the css file like this, Gatsby automatically adds it to the index.html files.
// So, it needs to be imported but it need not be "used" in code.
import * as SB_PARSER_CSS from "@springboarddev/sb-parser/design-tokens.css";

export const GlobalStyles = createGlobalStyle`

:root {
  --border-color-light: rgb(0, 0, 0) rgb(0, 0, 0) rgba(0, 0, 0, 0.1);
  --border-color-dark: rgb(255, 255, 255) rgb(255, 255, 255) rgba(255, 255, 255, 0.1);
}

${(props) =>
	props.vertical === "b2u" &&
	css`
		body {
			margin: 0;
			font-family: sans-serif;
			font-display: swap;
		}
		* {
			box-sizing: border-box;
		}
		.row {
			margin-right: 0;
			margin-left: 0;
		}
	`};

${(props) =>
	props.vertical === "b2c" &&
	css`
		body {
			margin: 0;
			font-family: "Roboto", sans-serif;
			font-display: swap;
		}
		.grecaptcha-badge {
			display: none !important;
		}
		* {
			box-sizing: border-box;
			font-family: "Haffer XH", sans-serif;
		}

		i.fa {
			font-family: "FontAwesome" !important;
		}
	`};

${(props) =>
	props.vertical === "rb-b2c" &&
	css`
		:target {
			scroll-margin-top: 80px;
		}
		/* html {
		scroll-behavior: smooth; 
	} */
		body {
			margin: 0;
			font-family: "Haffer XH", sans-serif;
		}
		* {
			box-sizing: border-box;
			font-family: "Haffer XH", sans-serif;
		}
		.grecaptcha-badge {
			display: none !important;
		}

		i.fa {
			font-family: "FontAwesome" !important;
		}
		.sticky-secondary-nav-wrapper {
			position: sticky;
			z-index: 10;
			top: -2px;
		}
	`};

// Common CSS rules
.sb-sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.intercom-facade > .live-chat-loader-placeholder > * {
	background: var(--secondary-purple-300) !important;
	bottom: 70px !important;
	@media only screen and (max-width: 450px) {
		bottom: 20px !important;
	}
}
`;
