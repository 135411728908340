import { isBusinessVerticalB2U } from "./helpers";

export const AddReferrer = () => {
	if (!document.referrer) return;
	let referrer2Url = new URL(document.referrer);
	const isB2U = isBusinessVerticalB2U();
	if (isB2U) {
		if (
			referrer2Url?.indexOf?.(
				"career-bootcamp" || "careerbootcamps" || "usfbootcamps",
			) === -1
		) {
			document.cookie = `referer2=${document.referrer};domain=${window.location.host};`;
		}
	} else {
		if (referrer2Url.hostname.indexOf(".springboard.com") === -1) {
			document.cookie = `referer2=${document.referrer};domain=.springboard.com;path=/;`;
		}
	}
};
