import CourseMenuTransform from "./NavigationHeader/CourseMenu/transform";
import MegaCourseMenuTransform from "./NavigationHeader/MegaCourseMenu/transform";

const transformTextLinkHeader = ({
	link = { link: "", type: "internal", target: "_self" },
	text = "",
}) => {
	return {
		text,
		cta: {
			link: {
				link: link.link,
				target: link.target || "_self",
				type: link.type || "internal",
			},
			event: "header_nav_menu_link_click",
		},
	};
};
const transformGenericHeaderMenu = (menus = []) => {
	let menuList = [];
	menus.forEach((x) => {
		const isLinkMenu = x?.hasOwnProperty("link");
		if (isLinkMenu) {
			const linkMenu = transformTextLinkHeader(x);
			menuList.push({ ...linkMenu, type: "LinkMenu" });
		} else {
			const dropdownMenu = {
				label: "",
				items: {},
				type: "",
			};
			const { menu = [], label = "", menuFooter = [] } = x;
			dropdownMenu.label = label;
			if (menu[0].component === "CourseMenu") {
				dropdownMenu.items = CourseMenuTransform(menu[0]);
				dropdownMenu.type = "CourseMenu";
			} else if (menu[0].component === "megaCourseMenu") {
				dropdownMenu.items = MegaCourseMenuTransform(menu[0]);
				dropdownMenu.type = "megaCourseMenu";
			} else {
				dropdownMenu.items = menu[0]?.items.map(transformTextLinkHeader);
				dropdownMenu.type = "GenericMenu";
				if (menuFooter.length) {
					dropdownMenu["footer"] = {
						text: menuFooter[0].text,
						linkCta: menuFooter[0]?.linkCta?.[0] || {},
						buttonCta: menuFooter[0]?.buttonCta?.[0] || {},
					};
				}
			}
			menuList.push(dropdownMenu);
		}
	});
	return menuList;
};

const transformCourses = (menu = {}) => {
	if (menu.component === "CourseMenu") {
		return CourseMenuTransform(menu);
	} else if (menu.component === "megaCourseMenu") {
		return MegaCourseMenuTransform(menu);
	}
	return {};
};

export default (headerInput) => {
	let header = {};
	const isGeneric = headerInput?.component === "navigationHeaderGeneric";
	if (headerInput) {
		if (isGeneric) {
			const {
				logo = {},
				logoOnDark = {},
				menus = [],
				callUsLabel,
				callUsModalDetails,
				loginLabel = "Login",
				disableLogin = false,
				disableHamburger = false,
				topNav = [],
				hidePreNav = false,
				pageSection,
				isPaidLander,
				isHeaderSticky,
				isHeaderLight,
				isLogoCenteredInMobile = false,
				headerCTA,
				stickyHeader = {},
				pencilBanner = {},
			} = headerInput;

			header = {
				logo,
				logoOnDark,
				menuList: transformGenericHeaderMenu(menus),
				callUsLabel,
				callUsModalDetails: callUsModalDetails?.[0] || {},
				loginLabel,
				paidLander: isPaidLander,
				isB2Crebrand: true,
				isGenericHeader: true,
				disableLogin,
				disableHamburger,
				topNav,
				hidePreNav,
				pageSection,
				isHeaderSticky,
				isHeaderLight,
				isLogoCenteredInMobile,
				headerCTA,
				stickyHeader,
				pencilBanner,
			};
		} else {
			const {
				courses = [],
				whySpringboard = [],
				forPartnersItems = [],
				resourcesItems = [],
				additionalLinks = [],
				callUsLabel,
				callUsModalDetails,
				loginLabel = "Login",
				hasDarkBackground,
				pageSection,
				isPaidLander,
				isHeaderSticky,
				isHeaderLight,
				headerCTA,
				pencilBanner = {},
			} = headerInput;

			header = {
				courseMenu: transformCourses(courses[0]),
				whySpringboardItems: whySpringboard.map(transformTextLinkHeader),
				forPartnersItems: forPartnersItems.map(transformTextLinkHeader),
				resourcesItems: resourcesItems.map(transformTextLinkHeader),
				additionalLinks: additionalLinks.map(transformTextLinkHeader),
				callUsLabel,
				callUsModalDetails: callUsModalDetails?.[0] || {},
				loginLabel,
				paidLander: isPaidLander,
				hasDarkBackground: hasDarkBackground,
				isB2Crebrand: true,
				isHeaderSticky,
				isHeaderLight,
				pageSection,
				headerCTA: headerCTA[0],
				pencilBanner,
			};
		}
	}

	return header;
};
