const getVWOCookies = () => {
	try {
		const cookies = {};
		document.cookie.split(";").forEach((c) => {
			cookies[c.split("=")[0].trim()] = c.split("=")[1];
		});
		const combiCookieKeys = Object.keys(cookies).filter((item) => {
			return item.startsWith("_vis_opt_exp_") && item.endsWith("_combi");
		});
		const res = {};
		Object.keys(cookies).forEach((key) => {
			if (combiCookieKeys.indexOf(key) !== -1) {
				res[key] = cookies[key];
			}
		});
		return res;
	} catch (err) {
		console.error("Cookie is not set!", err);
	}
};

export const getVwoCampaignId = () => {
	// Create VWO Campaign Id and Variation Id combination string from Cookies
	let cookie = getVWOCookies() || {};
	let campaignVariationIdStr = Object.entries(cookie).reduce((total, pair) => {
		const [key, value] = pair;
		let keyExt = key.replace("_vis_opt_exp_", "").replace("_combi", "");
		total = total ? total + "_" + keyExt + "-" + value : keyExt + "-" + value;
		return total;
	}, "");

	return campaignVariationIdStr;
};

export const getVwoCampaignIdFromWindow = () => {
	let campaignVariationIdStr = "";
	if (typeof window !== "undefined") {
		// Create VWO Campaign Id and Variation Id combination string from window
		const variationData = window._vwo_campaignData;
		const campaignIdArray = variationData ? Object.keys(variationData) : [];
		campaignIdArray.map((campaignId, index) => {
			const variationId = variationData[campaignId].n;
			campaignVariationIdStr += `${campaignId}-${variationId}${
				index !== campaignIdArray.length - 1 ? "-" : ""
			}`;
		});
	}
	return campaignVariationIdStr;
};

// this needs to be triggered to reset the running VWO experiments(on every route change)
export const resetVwoTestParams = () => {
	const vwoTestVariables = [
		"APPLY_WITH_LINKEDIN_VWO_TEST",
		"DISPLAY_INLINE_FORM",
	];
	vwoTestVariables.forEach((value) => {
		if (window[value]) {
			window[value] = false;
		}
	});
};
