import { ORDER_BY } from "../constants/config/config";
import { getVwoCampaignId } from "./VWO";
import { segmentTrackEvent, segmentIdentifyEvent } from "./SegmentEvents";
import { HUBSPOT_CUSTOM_EVENT_API, ACTIVE_CAMPAIGN_URL } from "../constants";
import {
	addToDataLayer,
	callIdentifyEvent,
	getCSRFTokenFromCookie,
} from "./utils";

export const generateTimeStamp = () => Math.floor(new Date().getTime() / 1000);

let activeCampainFieldValues = {
	preferred_course: "13",
	gender_self_described_: "16",
	terms_and_conditions: "17",
	zip: "11",
};

export const appendAutoRefreshOnSave = (previewToken) => {
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${previewToken}`;
	script.onload = () => {
		if (window.storyblok) {
			window.storyblok.on(["published", "change"], (event) => {
				window.location.reload();
			});
		}
	};
	document.body.appendChild(script);
};

export const getSpaceId = (spaceList, spaceName) => {
	let spaceID = null;
	spaceList.find(({ name, id }) => {
		if (spaceName === name) {
			spaceID = id;
			return true;
		}
	});
	return spaceID;
};

export const getEssentialData = (story) => {
	const { full_slug, slug, uuid, first_published_at, published_at, name } =
		story;

	return {
		full_slug,
		slug,
		uuid,
		first_published_at,
		published_at,
		name,
	};
};

// return in format June 25, 2021
export const formatDate = (date, format = "M/d/yy") => {
	const month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	if (!date || date.length === 0) {
		return "";
	}
	[date] = date.split("T");
	let tempDate = new Date(date);
	if (tempDate != "Invalid Date") {
		if (format === "M/d/yy") {
			return `${
				tempDate.getUTCMonth() + 1
			}/${tempDate.getUTCDate()}/${tempDate.getUTCFullYear()}`;
		}

		if (format === "MMM d, y") {
			return `${
				month[tempDate.getUTCMonth()]
			} ${tempDate.getUTCDate()}, ${tempDate.getUTCFullYear()}`;
		}
	} else {
		console.error("Error in formmating date", date);
		return "";
	}
};

export const addVwoCampaignId = (event) => {
	if (
		event.data.type === "hsFormCallback" &&
		event.data.eventName === "onFormReady"
	) {
		const campaignVariationIdStr = getVwoCampaignId();

		let divElements = [
			...document.querySelectorAll(`[id^=hubspotForm-${event.data.id}]`),
		];
		const variationId = getFormField(divElements, "variationid");
		if (variationId && variationId.length)
			updateInputsValue(variationId, campaignVariationIdStr);
	}
};

export const triggerHubspotCustomEventAPI = ({
	workshop_id,
	email,
	preferred_course,
	...rest
}) => {
	const apiBaseUrl = process.env.GATSBY_API_BASE_URL;
	let bodyObject = {
		email: email,
		event_name: "syllabus_download",
		page_url: window?.location?.href,
		...(workshop_id && { course_id: workshop_id }),
		...(preferred_course && { course_acronym: preferred_course }),
		...rest,
	};
	const csrfTokenValue = getCSRFTokenFromCookie();
	return fetch(`${apiBaseUrl}${HUBSPOT_CUSTOM_EVENT_API}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"x-csrftoken": csrfTokenValue,
		},
		body: JSON.stringify(bodyObject),
	}).catch((error) =>
		console.error("Failed to call Hubspot Custom Event", error),
	);
};

export const hbsptEventHandler = (event, additionalData = {}) => {
	addReferralAndReferer2(event);
	addVwoCampaignId(event);
	if (
		event.data.type === "hsFormCallback" &&
		event.data.eventName === "onFormReady"
	) {
		setTimeout(() => {
			const iframe = document.getElementsByClassName("hs-form-iframe");
			for (let i = 0; i < iframe.length; i++) {
				iframe[i].className = "hs-form-iframe hsform-loaded";
			}
		}, 500);
	}

	if (
		event.data.type === "hsFormCallback" &&
		event.data.eventName === "onFormSubmit"
	) {
		let divElements = [
			...document.querySelectorAll(`[id^=hubspotForm-${event.data.id}]`),
		];

		divElements.map((divElement) => {
			let iframeElement = divElement.childNodes[0];

			let style = document.createElement("style");
			style.textContent =
				".submitted-message {" +
				"  text-align: center !important;" +
				"  color:  white !important;" +
				"  font-size:  18px !important;" +
				"  line-height: 23px !important;" +
				"}";

			iframeElement.contentDocument.head.appendChild(style);
		});

		let formData = event.data.data;
		const isLeadForm = formData.filter(
			(data) =>
				data.name === "isleadform" &&
				(data.value === "true" || data.value === true),
		).length
			? true
			: false;

		// pv - previous value, cv- current value
		const modifiedFormData = formData.reduce((pv, cv) => {
			pv[cv.name] = cv.value;
			return pv;
		}, {});
		const referrer2Value = checkReferrer(document.referrer);

		if (isLeadForm) {
			const eventData = {
				course_slug: modifiedFormData?.preferred_course,
				referral: decodeURI(window.location.href),
				referral2: referrer2Value,
				lead_type: "SD",
				category: "Form Submission",
				hit_timestamp: getTimeStamp(),
				container_ID: process.env.GATSBY_GTM_CONTAINER_ID,
				label: decodeURIComponent(window.location.pathname),
				variant_id: modifiedFormData?.variationid,
				form_id: event.data.id,
				...additionalData,
			};
			addToDataLayer({ event: "syllabus_download", ...eventData });
			callIdentifyEvent({
				email: modifiedFormData?.email,
				phone: modifiedFormData?.phone,
				...eventData,
			});

			let contact = {
				fieldValues: [],
			};
			const DefaultActiveCampaignFields = {
				email: "email",
				firstname: "firstName",
				lastname: "lastName",
				phone: "phone",
			};

			formData.map((data) => {
				if (DefaultActiveCampaignFields.hasOwnProperty(data.name)) {
					contact[DefaultActiveCampaignFields[data.name]] = data.value;
				} else if (activeCampainFieldValues.hasOwnProperty(data.name)) {
					contact.fieldValues.push({
						field: activeCampainFieldValues[data.name],
						value: data.value,
					});
				}
			});
			try {
				const hubspotAPI = () => {
					const { workshop_id } = additionalData;
					const { email, preferred_course } = modifiedFormData;
					triggerHubspotCustomEventAPI({
						workshop_id,
						email,
						preferred_course,
					});
				};
				const apiCalls = [hubspotAPI()];
				Promise.all(apiCalls)
					.then((values) => {
						console.log("Lead captured successfully!");
					})
					.catch((error) => {
						console.error("Failed to execute API calls:", error?.message);
					});
			} catch (error) {
				console.error("error while sending contact", error);
			}
		} else {
			const eventData = {
				course_slug: modifiedFormData?.preferred_course,
				referral: decodeURI(window.location.href),
				referral2: referrer2Value,
				lead_type: "LFS",
				category: "Form Submission",
				hit_timestamp: getTimeStamp(),
				container_ID: process.env.GATSBY_GTM_CONTAINER_ID,
				label: decodeURIComponent(window.location.pathname),
				variant_id: modifiedFormData?.variationid,
				form_id: event.data.id,
				...additionalData,
			};
			addToDataLayer({ event: "lead_form_submit", ...eventData });
			callIdentifyEvent({
				email: modifiedFormData?.email,
				phone: modifiedFormData?.phone,
				...eventData,
			});
			window.contacts = {
				email: modifiedFormData?.email,
				firstname: modifiedFormData?.firstname,
				lastname: modifiedFormData?.lastname,
				phone: modifiedFormData?.phone,
			};
		}
	} else {
		event.preventDefault();
	}
};

export const getFaviconInfo = (url) => {
	if (url) {
		const fileType = getFileType(url);
		if (fileType === "ico") {
			return {
				type: "image/x-icon",
				url,
			};
		} else if (fileType) {
			return {
				type: `image/${fileType}`,
				url,
			};
		}
	}
	console.error("Not able to set Favicon", url);
	return { url };
};

export const getFileType = (url) => {
	return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const sortNumberBy = (list = [], key, orderBy = ORDER_BY.ASCENDING) => {
	return list.sort((a, b) => {
		if (orderBy === ORDER_BY.ASCENDING) {
			return b[key] - a[key];
		} else {
			return a[key] - b[key];
		}
	});
};

export const getFormField = (element, type) => {
	return (
		element.length &&
		element[0]?.lastElementChild?.contentWindow?.document.querySelectorAll(
			`input[name$="${type}"]`,
		)
	);
};

const getCookie = (cookieName) => {
	const cookies = {};
	document.cookie.split(";").forEach((c) => {
		cookies[c.split("=")[0].trim()] = c.split("=")[1];
	});
	return cookies[cookieName];
};

export const checkReferrer = (referrer2) => {
	if (!referrer2) {
		return "";
	}
	const referrer2Url = new URL(referrer2);
	const hostname = referrer2Url.hostname;
	referrer2 =
		hostname.indexOf(
			"career-bootcamp" || "careerbootcamps" || "usfbootcamps",
		) === -1
			? referrer2
			: "";
	return referrer2;
};

export const addReferralAndReferer2 = (event) => {
	if (
		event.data.type === "hsFormCallback" &&
		event.data.eventName === "onFormReady"
	) {
		let divElements = [
			...document.querySelectorAll(`[id^=hubspotForm-${event.data.id}]`),
		];
		try {
			const referral = getFormField(divElements, "referral");
			if (referral && referral.length)
				updateInputsValue(referral, decodeURI(window.location.href));

			const referer2 = getFormField(divElements, "referral1");
			if (referer2 && referer2.length) {
				if (!getCookie("referer2")) {
					const referrer2Value = checkReferrer(document.referrer);
					if (referrer2Value) {
						document.cookie = `referer2=${referrer2Value};domain=${window.location.host};`;
					}
					updateInputsValue(referer2, referrer2Value);
				} else {
					updateInputsValue(referer2, checkReferrer(getCookie("referer2")));
				}
			}
		} catch (error) {
			console.error("Could Not able to set referral and referer2", error);
		}
	}
};

export const updateInputsValue = (inputs, value) => {
	inputs.forEach((input) => {
		// adding Active Campaign Field id's and values to object.
		activeCampainFieldValues[input.name] = input.value;
		input.value = value;
	});
};

export const getOriginUrl = () => {
	if (typeof window !== "undefined" && window.location.origin) {
		return window.location.origin;
	}
	return "";
};

export const getTimeStamp = () => {
	// Get local time as ISO string with offset at the end
	var now = new Date();
	var tzo = -now.getTimezoneOffset();
	var dif = tzo >= 0 ? "+" : "-";
	var pad = function (num) {
		var norm = Math.abs(Math.floor(num));
		return (norm < 10 ? "0" : "") + norm;
	};
	return (
		now.getFullYear() +
		"-" +
		pad(now.getMonth() + 1) +
		"-" +
		pad(now.getDate()) +
		"T" +
		pad(now.getHours()) +
		":" +
		pad(now.getMinutes()) +
		":" +
		pad(now.getSeconds()) +
		"." +
		pad(now.getMilliseconds()) +
		dif +
		pad(tzo / 60) +
		":" +
		pad(tzo % 60)
	);
};

export const getDateString = (date) => {
	if (!date) {
		return null;
	}
	const dateObj = new Date(date);
	const month = (dateObj.getUTCMonth() + 1).toString();
	const day = dateObj.getUTCDate().toString();
	const year = dateObj.getUTCFullYear();
	return `${month}/${day}/${year}`;
};

/**
 * This function return the input number as comma seperated value
 * @param {number} value
 * @returns String
 */
export const formatCurrency = (value) =>
	Intl.NumberFormat("en-US").format(value);

/**
 * Return a unique id
 * @returns number
 */
export const getUniqueId = () => Math.floor(Math.random() * 10 ** 6 + 1);

/**
 * Return whether the bussiness-vertical is b2u
 * @returns boolean
 */
export const isBusinessVerticalB2U = () =>
	process.env.GATSBY_BUSINESS_VERTICAL === "b2u";
