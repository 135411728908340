const TYPES = {
	fetchFormat: "f",
	crop: "c",
	effect: "e",
	flags: "fl",
	gravity: "g",
	height: "h",
	radius: "r",
	quality: "q",
	width: "w",
	dpr: "dpr",
};

export const getCloudinaryUrl = (path, options = {}) => {
	const cloudName =
		options.cloudName === "career-bootcamp"
			? "career-bootcamp"
			: "springboard-images";
	const scheme = "https";
	const source = options.source || "upload";

	const keys = Object.keys(options);
	const urlParams = keys
		.map((key) => {
			const prefix = TYPES[key];
			const value = options[key];
			if (prefix && value) {
				return `${prefix}_${value}`;
			}
		})
		.filter(Boolean)
		.join(",");

	const version = options.version || "v1";

	const url = [
		`${scheme}://res.cloudinary.com`,
		encodeURIComponent(cloudName),
		"image",
		source,
		urlParams,
		version,
		path,
	]
		.filter(Boolean)
		.join("/");

	return url;
};

export const getCloudinaryUrlForDOM = (path) => {
	let imageLink = path.replace(/.png|.jpg|.jpeg/g, ".webp");
	imageLink = imageLink.substring(
		imageLink.indexOf("v1/") + 3,
		imageLink.length,
	);
	const imageOptions = {
		quality: "auto",
		fetchFormat: "webp",
		dpr: "auto",
		flags: "lossy",
	};

	imageLink = getCloudinaryUrl(imageLink, {
		...imageOptions,
	});
	return imageLink;
};
