const CourseMenuTransform = (props) => {
	let content = {
		items: [],
		footer: {
			text: "",
			linkCta: {},
			buttonCta: {},
		},
	};
	const { courseMenuItems = [], courseMenuFooter = [] } = props;
	courseMenuItems.forEach((item) => {
		let courseItem = {};
		courseItem.name = item.name;
		courseItem.icon = item.iconName;
		courseItem.bgColor = item.bgColor;
		courseItem.courseColor = item.courseColor;
		courseItem.actions = {
			description: item.actionDescription,
			courses: [],
		};
		item.courseMenuItemAction.forEach((action) => {
			let course = {};
			course.name = action.name;
			course.isJobGuarantee = action.hasJobGuarantee;
			course.link = action.link;
			courseItem.actions.courses.push(course);
		});

		content.items.push(courseItem);
	});
	content.footer.text = courseMenuFooter[0]?.text;
	content.footer.linkCta = courseMenuFooter[0]?.linkCta?.[0] || {};
	content.footer.buttonCta = courseMenuFooter[0]?.buttonCta?.[0] || {};

	return content;
};

export default CourseMenuTransform;
