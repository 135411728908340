export const useScript = (url, async, body, location = "head") => {
	const script = document.createElement("script");

	if (url) {
		script.src = url;
	}

	if (async) {
		script.async = true;
	}

	if (body) {
		script.text = body;
	}

	script.type = "text/javascript";

	if (location === "body") {
		let ele = document.getElementsByTagName("body")[0];
		ele.appendChild(script);
	}

	if (location === "head") {
		document.head.appendChild(script);
	}

	return () => {
		if (location === "head") {
			document.head.removeChild(script);
		} else {
			document.body.removeChild(script);
		}
	};
};

export const useNoScript = (body, location = "head") => {
	const noScript = document.createElement("noscript");

	if (body) {
		noScript.text = body;
	}

	if (location === "head") {
		document.head.appendChild(noScript);
	}

	return () => {
		if (location === "head") {
			document.head.removeChild(noScript);
		} else {
			document.body.removeChild(noScript);
		}
	};
};
