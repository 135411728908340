import { USD_COUNTRY_CODES } from "./../constants/currency";
import { INVALID_REQUEST } from "./../constants/messages";
export const lightOrDark = (color) => {
	// Check the format of the color, HEX or RGB?
	let r, g, b;
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		let colorParts = color.match(
			/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
		);

		r = colorParts[1];
		g = colorParts[2];
		b = colorParts[3];
	} else {
		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		let colorParts = +(
			"0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
		);

		r = colorParts >> 16;
		g = (colorParts >> 8) & 255;
		b = colorParts & 255;
	}

	// HSP equation from http://alienryderflex.com/hsp.html
	let hsp = Math.round(
		Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)),
	);

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 200) {
		return "light";
	} else {
		return "dark";
	}
};

export function debounce(func, delay) {
	let inDebounce;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(inDebounce);
		inDebounce = setTimeout(() => func.apply(context, args), delay);
	};
}

export const isDateValid = (dateString) => {
	if (!dateString) return false;

	const datems = new Date(dateString).getTime();

	if (isNaN(datems)) {
		return false;
	}

	return true;
};

export const getDateMMDDYYYY = (dateString) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const dateObject = new Date(dateString);

	return `${
		months[dateObject.getMonth()]
	} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
};

export const isEmailValid = (email = "") => {
	const trimmedEmail = email.trim();
	return (
		trimmedEmail &&
		/^([a-zA-Z0-9_.+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,24})$/.test(
			trimmedEmail,
		)
	);
};

export const isPhoneNumberValid = (phoneNumber = "") => {
	const trimmedNumber = phoneNumber.trim();
	return (
		trimmedNumber &&
		/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(trimmedNumber)
	);
};

export const isCountryCodeValid = (code = "") => {
	const trimmedNumber = code.trim();
	return trimmedNumber && /^(\+?\d{1,3}|\d{1,4})$/.test(trimmedNumber);
};

export function throttle(func, limit) {
	let lastFunc;
	let lastRan;
	return function () {
		const context = this;
		const args = arguments;
		if (!lastRan) {
			func.apply(context, args);
			lastRan = Date.now();
		} else {
			clearTimeout(lastFunc);
			lastFunc = setTimeout(
				function () {
					if (Date.now() - lastRan >= limit) {
						func.apply(context, args);
						lastRan = Date.now();
					}
				},
				limit - (Date.now() - lastRan),
			);
		}
	};
}

export const isBrowser = () => {
	return typeof window !== undefined;
};

export const setCookie = (cname, cvalue, extimeMs, domain) => {
	if (typeof document !== "undefined") {
		const encodedCvalue = encodeURIComponent(cvalue);
		let expires = "";
		if (extimeMs) {
			const d = new Date();
			d.setTime(d.getTime() + extimeMs);
			expires = `expires=${d.toGMTString()};`;
		}
		document.cookie = `${cname}=${encodedCvalue};path=/;${expires}${
			domain ? `domain=${domain};` : ""
		}`;
	}
};

export const getCookie = (cname) => {
	const name = `${cname}=`;
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return decodeURIComponent(c.substring(name.length, c.length));
		}
	}
	return "";
};

export const deleteCookie = (cname) => {
	document.cookie = `${cname}=;path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
};

export const getXCSRFToken = (apiBaseUrl) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(`${apiBaseUrl}/token`, {
				credentials: "include",
			}).then((res) => res.json());

			const { token } = response;

			return resolve(token);
		} catch (err) {
			return reject(err);
		}
	});
};

export const callUserAPI = (apiBaseUrl = null) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!apiBaseUrl)
				return reject(
					new Error("apiBaseUrl not passed to callUserAPI:" + apiBaseUrl),
				);
			const response = await fetch(`${apiBaseUrl}/user/`, {
				credentials: "include",
			}).then((res) => res.json());
			return resolve(response);
		} catch (err) {
			return reject(err);
		}
	});
};

export const callLearningPathInterestedAPI = (
	email = null,
	workshopId = null,
	apiBaseUrl = null,
	additionalFields = {},
) => {
	const formData = {
		js_disabled: "yes",
		course_id: workshopId,
		email: email,
		...additionalFields,
	};
	const body = new FormData();
	Object.keys(formData).forEach((key) => {
		body.append(key, formData[key]);
	});
	body.append("csrfmiddlewaretoken", getCSRFTokenFromCookie());
	return new Promise(async (resolve, reject) => {
		try {
			if (!(email && workshopId && apiBaseUrl)) {
				return reject(
					new Error(
						"Required props not passed to callLearningPathInterestedAPI. \nemail:" +
							email +
							"\nworkshopId:" +
							workshopId +
							"\napiBaseUrl:" +
							apiBaseUrl,
					),
				);
			}
			const csrfTokenValue = getCSRFTokenFromCookie();
			const response = await fetch(`${apiBaseUrl}/interested/resources/`, {
				method: "POST",
				headers: {
					"x-csrftoken": csrfTokenValue,
				},
				body: body,
				referrerPolicy: "no-referrer-when-downgrade",
				credentials: "include",
			}).then((res) => res.json());
			return resolve(response);
		} catch (err) {
			return reject(err);
		}
	});
};

export const replaceCurrencySymbol = (text, countryCode = "") => {
	if (!countryCode) return text;

	let formattedText = text;

	const currencies = [
		{
			symbol: "$",
			countryCodes: USD_COUNTRY_CODES,
			replacementText: "USD ",
		},
	];

	for (let i = 0; i < currencies.length; i++) {
		const { symbol, countryCodes, replacementText } = currencies[i];

		if (countryCodes.includes(countryCode.toUpperCase())) {
			formattedText = text.split(symbol).join(replacementText);
		}
	}

	return formattedText;
};

export const getCountry = () => {
	const [subdomain] = window.location.host.split(".");

	const countryCode = subdomain.includes("-")
		? subdomain.split("-")[1]
		: subdomain;

	switch (countryCode) {
		case "in":
			return "in";

		default:
			return "www";
	}
};

// export const scrollToElement = (id, margin = 50) => {
//   const $elementWithId = document.querySelector(`#${id}`);
//   if ($elementWithId) {
//     const yCoordinate =
//       $elementWithId.getBoundingClientRect().y + window.scrollY;
//     window.scrollTo(0, yCoordinate - margin);
//   }
// };

// export const isElementInViewport = (rect) => {
//   return (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <=
//       (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.right <= (window.outerWidth || document.documentElement.clientWidth)
//   );
// };

export const isValidJsonString = (objStr) => {
	try {
		const obj = JSON.parse(objStr);
		return !!obj && typeof obj === "object" && !Array.isArray(obj);
	} catch {
		return false;
	}
};

export const getGclid = () => {
	const gclid_obj = window.localStorage.getItem("gclid");
	return (
		gclid_obj && isValidJsonString(gclid_obj) && JSON.parse(gclid_obj)["value"]
	);
};

export const getUrlParams = () => {
	const params = {};
	const url = new URL(window.location.href);
	url.searchParams.forEach((value, key) => {
		params[key] = value;
	});
	return params;
};

export const addToDataLayer = (data) => {
	console.log("addToDataLayer", data);
	if (typeof window != "undefined") {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(data);
	}
};

export const getResult = (response) => {
	if (!response.ok) {
		// create error object and reject if not a 2xx response code
		let err = new Error(INVALID_REQUEST + ":" + response.status);
		err.response = response;
		err.status = response.status;
		throw err;
	}
	return response.json();
};

export const getVideoId = (url = "") => {
	return url?.split("v=")[1].split("&")[0] || "";
};

export const isSafariBrowser = () => {
	if (typeof window !== "undefined") {
		let isSafari =
			/constructor/i.test(window.HTMLElement) ||
			(function (p) {
				return p.toString() === "[object SafariRemoteNotification]";
			})(
				!window["safari"] ||
					(typeof safari !== "undefined" && window["safari"].pushNotification),
			);
		return isSafari;
	}
	return false;
	//Reference: https://stackoverflow.com/questions/9847580
};

export const getSegmentURL = (vertical) => {
	let urlPrefix = "";
	if (vertical === "b2c" || vertical === "rb-b2c") {
		urlPrefix = "https://analytics-proxy.springboard.com/segment_cdn/";
	} else if (vertical === "b2u") {
		urlPrefix = "https://cdn.segment.com/analytics.js/v1/";
	}
	const segmentKey = process.env.GATSBY_SEGMENT_KEY;
	const segmentUrl = urlPrefix + segmentKey + "/analytics.min.js";
	return segmentUrl;
};

export const getGa4Id = () => {
	try {
		const cookies = {};
		document.cookie.split(";").forEach((c) => {
			cookies[c.split("=")[0].trim()] = c.split("=")[1];
		});
		let gidVal = cookies["_gid"];
		gidVal = gidVal.split(".");
		let length = gidVal.length;
		gidVal = gidVal[length - 2] + "." + gidVal[length - 1];
		return gidVal;
	} catch (err) {
		console.error("Cookie does not have value for _gid", err);
		return "";
	}
};

export const getCSRFTokenFromCookie = () => {
	const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT;
	const API_BASE_URL = process.env.GATSBY_API_BASE_URL;
	let csrfTokenValue = "";
	if (ENVIRONMENT === "production") {
		csrfTokenValue = getCookie("wwwcsrftoken");
	} else {
		const apiNamespace = new URL(API_BASE_URL)?.host?.replace(
			"-api.springboard.com",
			"",
		);
		csrfTokenValue = getCookie(apiNamespace + "csrftoken");
	}
	return csrfTokenValue;
};

export const callIdentifyEvent = (eventData) => {
	let contact = {
		email: eventData?.email,
		phone_no: eventData?.phone,
		form_id: eventData?.form_id,
	};
	addToDataLayer({
		event: "identify",
		...contact,
	});
};

export const callB2CIdentifyEvent = (email) => {
	addToDataLayer({
		event: "identify",
		email: email,
	});
};
