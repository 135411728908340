import { useScript } from "@website-builder/utilities-index";

const Marketo = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	const body = `(function() {
    var didInit = false;
    function initMunchkin() {
      if (didInit === false) {
        didInit = true;
        Munchkin.init("268-FLK-694");
      }
    }
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "/${scriptPrefixPath}/Marketo.js";
    s.onreadystatechange = function() {
      if (this.readyState == "complete" || this.readyState == "loaded") {
        initMunchkin();
      }
    };
    s.onload = initMunchkin;
    document.getElementsByTagName("head")[0].appendChild(s);
  })();`;

	useScript("", true, body, "body");
};

export default Marketo;
