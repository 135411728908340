import { useScript, useNoScript } from "@website-builder/utilities-index";
const LinkedinInsight = (serviceConfigs, assetPrefixPath, scriptPrefixPath) => {
	let body = `_linkedin_partner_id = "37317";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);`;

	useScript("", true, body, "head");

	body = `(function(){var s = document.getElementsByTagName("script")[0];
  var b = document.createElement("script");
  b.type = "text/javascript";b.async = true;
  b.src = "/${scriptPrefixPath}/LinkedinInsight.js";
  s.parentNode.insertBefore(b, s);})();`;

	useScript("", true, body, "head");

	body = `<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=37317&fmt=gif" />`;

	useNoScript(body, "head");
};

export default LinkedinInsight;
