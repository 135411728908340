export const DEFAULTS = {
	FONT_FAMILY:
		"https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap",
	FAV_ICON: "https://www.springboard.com/homepage-assets/favicon.ico",
};

//prop -> content-type
//key -> layout component name
export const LAYOUT_CONFIG = {
	B2U: {
		Layout: "UniversityLayout",
		About: "UniversityLayout",
		Home: "UniversityLayout",
		FAQ: "UniversityLayout",
		ThankYou: "UniversityLayout",
		Lead: "LeadLayout",
		Program: "UniversityLayout",
		Experience: "ExperienceLayout",
		404: "NotFound",
		BlogIndex: "UniversityLayout",
		Blog: "BlogLayout",
		RebrandPage: "UniversityLayout",
		RebrandProgram: "UniversityLayout",
		RebrandBlogIndex: "RebrandBlogIndexLayout",
		RebrandBlog: "RebrandBlogPageLayout",
	},
	B2C: {
		"Learningpath-schema": "GenericLayout",
		"eBook-Page": "GenericLayout",
		"eBook-Homepage-Schema": "GenericLayout",
		"Learningpath-Homepage-Schema": "GenericLayout",
		"student-success-video": "GenericLayout",
		"student-success-page": "GenericLayout",
		"student-success-homepage": "GenericLayout",
		"landing-page": "GenericLayout",
		studentSuccessHomepage: "GenericLayout",
		Resources: "GenericLayout",
		postapp: "GenericLayout",
		Layout: "GenericLayout",
		course: "GenericLayout",
		page: "GenericLayout",
		Landing: "GenericLayout",
		// GrowthInfra : Check if below items could be deleted
		Blank: "GenericLayout",
		ki: "GenericLayout",
		Post: "GenericLayout",
		testFolder: "GenericLayout",
	},
	RB_B2C: {
		"eBook-Homepage-Schema": "GenericLayout",
		"student-success-video": "GenericLayout",
		"student-success-page": "GenericLayout",
		"student-success-homepage": "GenericLayout",
		"landing-page": "GenericLayout",
		Resources: "GenericLayout",
		postapp: "GenericLayout",
		Layout: "GenericLayout",
		course: "GenericLayout",
	},
};

export const STATIC_URLS = {
	STORYBLOK_MANAGEMENT_API: "https://mapi.storyblok.com/v1",
	STORYBLOCK_CMS_API: "https://api.storyblok.com/v1",
};

export const ORDER_BY = {
	ASCENDING: "asc",
	DESCEING: "desc",
};

export const UNI_BACKGROUD_COLOR_VAR_NAME = {
	DEFAULT: "default",
	PRIMARY: "primary",
	SECONDARY: "secondary",
};

export const DEFAULT_LOGO_KEY = {
	PRIMARY: "primary",
	SECONDARY: "secondary",
};

export const bannerFields = {
	HEADER: "HEADER",
	SUB_HEADER: "SUB_HEADER",
	CONTENT: "CONTENT",
};
