import { useScript } from "@website-builder/utilities-index";
const ExperianUniversalPixel = (
	serviceConfigs,
	assetPrefixPath,
	scriptPrefixPath,
) => {
	const body = `(function() {
                  function initTTDDOMReady() {
                    ttd_dom_ready( function() {
                      if (typeof TTDUniversalPixelApi === 'function') {
                          var universalPixelApi = new TTDUniversalPixelApi();
                          universalPixelApi.init("hvagpb0", ["m03pvw7"], "https://insight.adsrvr.org/track/up");
                      }
                    });
                  }
                  let s = document.createElement("script");
                  s.type = "text/javascript";
                  s.async = true;
                  s.src = "/${scriptPrefixPath}/ExperianUniversalPixel.js";
                  s.onload = initTTDDOMReady;
                  document.getElementsByTagName("head")[0].appendChild(s);
                })();`;

	useScript("", true, body, "head");
};

export default ExperianUniversalPixel;
